import { asBoolean } from '@komo-tech/core/utils/boolean';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { FieldValues } from 'react-hook-form';

import { FieldValidator } from './Validator';

type Predicate<TFieldValues> = (
  value: unknown,
  values: TFieldValues
) => boolean;

export interface RequiredValidatorOptions<TFieldValues = unknown> {
  enabled: boolean | Predicate<TFieldValues>;
  trim?: boolean;
  variant?: 'default' | 'array' | 'boolean';
  message?: string;
}

export function requiredValidator<
  TFieldValues extends FieldValues = FieldValues
>(
  options?: RequiredValidatorOptions<TFieldValues>
): FieldValidator<TFieldValues> {
  return (valueProp, values) => {
    let value = valueProp;

    if (isNil(options?.enabled)) {
      return null;
    }

    if (isFunction(options.enabled)) {
      if (!options.enabled(value, values)) {
        return null;
      }
    } else if (!options.enabled) {
      return null;
    }

    if (options?.trim && isString(value)) {
      value = (value || '').trim();
    }
    const variant = options?.variant || 'default';
    const returnMessage = options?.message || 'Field is required';
    if (isNil(value)) {
      return returnMessage;
    }
    let isValid = false;
    switch (variant) {
      case 'array':
        isValid = !!(value as unknown[]).length;
        break;
      case 'boolean':
        isValid = asBoolean(value as string);
        break;
      case 'default':
        isValid = value !== '';
        break;
    }
    return !isValid ? returnMessage : null;
  };
}

export function requiredTrimmedValidator(overrideMessage?: string) {
  return (value: string): string | undefined => {
    if (!(value || '').trim()) {
      return overrideMessage || 'Field is required';
    }
  };
}

export function requiredArrayIfValidator<TValues = any>(
  predicate: (value: any, values: TValues) => boolean,
  overrideMessage?: string
) {
  return (value: any[], values: TValues): string | undefined => {
    if (predicate(value, values) && (isNil(value) || !value.length))
      return overrideMessage || 'Field is required';
  };
}

export function requiredIfValidator<TValues = any>(
  predicate: (value: any, values: TValues) => boolean,
  overrideMessage?: string
) {
  return (value: any, values: TValues): string | undefined => {
    if (
      predicate(value, values) &&
      (value === null || value === undefined || value === '')
    ) {
      return overrideMessage || 'Field is required';
    }
    return undefined;
  };
}

export function requiredArrayValidator(overrideMessage?: string) {
  return (value: any[]): string | undefined => {
    if (isNil(value) || !value.length)
      return overrideMessage || 'Field is required';
  };
}

export function requiredArrayEveryValidator(
  predicate: (item) => boolean,
  overrideMessage?: string
) {
  return (value: any[]): string | undefined => {
    if (isNil(value) || !value.length || !value.every(predicate))
      return overrideMessage || 'Field is required';
  };
}

export function requiredArraySomeValidator(
  predicate: (item) => boolean,
  overrideMessage?: string
) {
  return (value: any[]): string | undefined => {
    if (isNil(value) || !value.length || !value.some(predicate))
      return overrideMessage || 'Field is required';
  };
}
